<template>
  <div>
    <div class="staffadd_top">
      <h3 style="margin-bottom:-13px;">编辑员工</h3>
      <el-divider></el-divider>
      <el-form
        style="margin-left:60px;"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="200px"
        class="demo-ruleForm"
      >
        <el-form-item label-width="100px" label="登录账号：" prop="user_name">
          <el-input style="width:355px;" clearable v-model.trim="ruleForm.user_name"></el-input>
        </el-form-item>

        <el-form-item label-width="100px" label="手机号：" prop="mobile">
          <el-input style="width:355px;" clearable v-model.trim="ruleForm.mobile"></el-input>
        </el-form-item>

        <el-form-item label-width="100px" label="邮箱：" prop="email">
          <el-input style="width:355px;" clearable v-model.trim="ruleForm.email"></el-input>
        </el-form-item>

      </el-form>
      <div class="sextr">
        <div style="width:50px;margin-left:108px;">角色：</div>
        <el-select style="width:355px;" v-model="value1" multiple placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.role_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <button @click="sechbntrole" class="shearkeybutto" type="primary" v-if="hasPerm(['admin.update'])">
        <span>保存</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [],
      value1: [],
      jieshou: null,
      // 角色
      checkAll: false,
      isIndeterminate: true,
      ruleForm: {},
      rules: {
        // name: [
        //   {
        //     required: true,
        //     message: "请输入姓名",
        //     trigger: "blur"
        //   },
        //   { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        //   { pattern: /^[0-9|^\\.]/, message: '数值不能为负数' },
        // ],
        user_name: [
          {
            required: true,
            message: "请输入登陆账号",
            trigger: "blur"
          }
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        role: [{ required: true, message: "请输入角色", trigger: "blur" }]
      }
    };
  },
  created() {
    this.cyinit();
    this.obtainit();
    // this.ruleForm = this.$route.params;
  },
  methods: {
    // 下拉选中的值
    anuik(e) {
      this.value1 = e;
    },
    obtainit() {
      this.$get(this.$apis.obtain).then(res => {
        this.options = res.data;
      });
    },

    cyinit() {
      this.jieshou = localStorage.getItem("keyid");
      this.$get(this.$apis.addiTion + "/" + this.jieshou).then(res => {
        this.ruleForm = res.data;
        this.value1 = JSON.parse(res.data.roles);
      });
    },
    sechbntrole() {
      let data = {
        user_name: this.ruleForm.user_name, //帐号-用户名
        mobile: this.ruleForm.mobile, //手机号
        email: this.ruleForm.email, //邮箱
        roles: JSON.stringify(this.value1) //角色的ids
      };
      this.$put(this.$apis.edIa + "/" + this.jieshou, data).then(res => {
        if (res.code == 200) {
          this.$message({
            message: "修改成功",
            type: "success"
          });
          this.$router.push('/setup/management/staff')
        }else{
             this.$message({
            message: res.message,
            type: "error"
          });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.staffadd_top {
  .sextr {
    display: flex;
    align-items: center;
  }
  margin-bottom: 10px;
  border-radius: 4px;
  background: #fff;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}
.shearkeybutto {
  margin: 20px 155px;
  width: 50px;
  height: 30px;
  background-color:var(--fontColor, #fb6638);;
  border-radius: 5px;
  span {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>>